import React from "react";

// import catAndHumanIllustration from "../images/cat-and-human-illustration.svg";

function HeroPage() {
    return (
        <section className="flex max-w-4xl mx-auto h-full">

            <div className="m-auto">
                <h2 className="mb-4 sm:text-8xl text-4xl font-bold text-center">
                    By Robotic
                </h2>

                <p className="leading-loose pl-2 text-center">
                    We make robots, and parts for robots in Australia.  {` `}
                    {/* <a
                        className="font-bold text-gray-900 no-underline"
                        href="https://byrobotic.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        By Robotic
                    </a> */}
                </p>
            </div>

            {/* <div className="basis-1/2">
                <img
                    alt="Cat and human sitting on a couch"
                    className=""
                    src={catAndHumanIllustration}
                />
            </div> */}


        </section>
    );
}

export default HeroPage;
